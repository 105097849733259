module controllers {
    'use strict';
    export module purchase {
        interface IPurchaseUpdateScope extends ng.IScope { }

        interface IPurchaseUpdateParams extends ng.ui.IStateParamsService {
            poId: number;
        }

        export class PurchaseOrderUpdateCtrl {
            static $inject = ["$scope", 'purchaseOrderService', '$rootScope', 'generalService', '$q', '$stateParams', 'currencyService', 'classificationValueService',
                'termsOfPaymentService', "$anchorScroll", "$state", "purchaseOrderSKUService",
                "countryService", "incotermService", "termsOfDeliveryService", "consigneeService", "deliveryInstructionService", "documentRepositoryService", "bsLoadingOverlayService",
                "lookupService", "skuService", "$timeout", '$transitions',"customAttributeService", "customAttributeAnswerOptionService", 'menuService',];

            public PurchaseOrderSearch: { id: number };
            public PurchaseOrder: interfaces.purchase.IPurchaseOrder;

            public POStatus: interfaces.master.IStatusData;

            poNumber: string;

            apiPOSkuList: uiGrid.IGridApi;

            apiDIList: uiGrid.IGridApi;

            selectedTab: number = 0;
            showAll: boolean = false;
            viewConsignmentRight: boolean = false;
            editConsingmentRight: boolean = false;

            documentRepository: interfaces.documentManagement.IDocumentRepository;

            termsOfDeliveryList: Array<interfaces.applicationcore.IDropdownModel>;

             //Attribute Grid
             apiCA: uiGrid.IGridApi;
             attributeList: interfaces.master.ICustomAttribute[] = [];
             attributeAnswerOptionList: interfaces.applicationcore.IDropdownModel[] = [];

            constructor(private $scope: IPurchaseUpdateScope, private purchaseOrderService: interfaces.purchase.IPurchaseOrderService,
                private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService, private $q: ng.IQService, private $stateParams: IPurchaseUpdateParams,
                private currencyService: interfaces.applicationcore.ICurrencyService, private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private termsOfPaymentService: interfaces.master.ITermsOfPaymentService,private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService, private purchaseOrderSKUService: interfaces.purchase.IPurchaseOrderSKUService,
                public countryService: interfaces.applicationcore.ICountryService, public incotermService: interfaces.applicationcore.IIncotermService,
                public termsOfDeliveryService: interfaces.master.ITermsOfDeliveryService, public consigneeService: interfaces.master.IConsigneeService,
                public deliveryInstructionService: interfaces.purchase.IDeliveryInstructionService, public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public bsLoadingOverlayService, private lookupService: interfaces.applicationcore.ILookupService,
                public skuService: interfaces.master.ISKUService, public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public customAttributeService: services.master.customAttributeService,
                public customAttributeAnswerOptionService: services.master.customAttributeAnswerOptionService,
                private menuService: interfaces.applicationcore.IMenuService,
                ) {

                if ($stateParams.poId === null) {
                    return;
                }

                this.PurchaseOrderSearch = { id: $stateParams.poId };

                this.loadPO();

                //Reloads PO after an update to SKU or DI.
                $transitions.onSuccess({
                    to: 'auth.PurchaseOrders.Update',
                    from: 'auth.PurchaseOrders.Update.**'
                }, () => {
                    this.loadPO();
                });
            }
            
            loadControls() {
                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        loadPromises.push(this.getDocuments(this.showAll));

                        return this.$q.all(loadPromises);
                    });
            }

            downloadToExcel() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        this.purchaseOrderService.GetPurchaseOrderDetailSKUListExcel(this.PurchaseOrder.Id).then(() => {
                        }, (data) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                        });
                    });
            }

            refreshTariffAndOrigin_click() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        this.SavePurchaseOrder().then(() => {
                            this.refreshTariffAndOrigin(this.PurchaseOrder.Id).then(() => {
                            }, (data) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                            });
                        });
                    });
            }

            refreshTariffAndOrigin(purchaseOrderId: number) {
                var defer = this.$q.defer();

                this.purchaseOrderService.refreshTariffAndOrigin(purchaseOrderId).save((result: interfaces.applicationcore.IMessageHandler) => {
                    defer.resolve();
                    this.generalService.displayMessageHandler(result);

                }, (failureData) => {
                    defer.reject();
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                });

                return defer.promise;
            }

            gvwPOSku: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerSkuGridApi(gridApi); },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "PurchaseOrderSKUId",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-disabled="!MODEL_COL_FIELD|| MODEL_COL_FIELD == 0" ui-sref="auth.PurchaseOrders.Update.Sku({posId: MODEL_COL_FIELD})" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-disabled="grid.appScope.pOUpdateCtrl.PurchaseOrder.LockdownType >= 1" ng-click="grid.appScope.pOUpdateCtrl.addInlineSku()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    cellClass: 'text-right',
                    width: 30,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false
                }, {
                    name: "PRODCODE",
                    displayName: "Product",
                    field: "ProductCode",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "SKUCODE",
                    displayName: "* SKU",
                    field: "SKU",
                    enableFiltering: true,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.PurchaseOrder ? this.PurchaseOrder.LockdownType < 1 : true },
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Code}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="code-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.pOUpdateCtrl.loadSKUs(searchText)" required="true" pagination-search="true" refresh-delay="1000"
                                                ng-change="grid.appScope.pOUpdateCtrl.skuChanged(model, row.entity)"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiPOSkuList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "SKUDESCRIPTION",
                    displayName: "SKU Description",
                    field: "SKU.Description",
                    width: 300,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.PurchaseOrder ? this.PurchaseOrder.LockdownType < 2 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-skudesc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "ORDQTY",
                        displayName: "* Order Qty",
                    field: "OrderedQty",
                    type: "number",
                    cellFilter: 'number: 5',
                    width: 90,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.PurchaseOrder ? this.PurchaseOrder.LockdownType < 1 : true },
                    cellClass: 'text-right',
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="orderedQty" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "ORDUOM",
                    displayName: "Order UoM",
                    field: "OrderUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "PRCQTY",
                    displayName: "Price Qty",
                    field: "PriceQty",
                    type: "number",
                    cellFilter: 'number: 5',
                    width: 90,
                    cellClass: 'text-right',
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "PRCUOM",
                    displayName: "Price UoM",
                    field: "PricingUnitOfMeasureCode",
                    width: 90,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "SUPPRICE",
                        displayName: "* Supplier Price",
                    field: "SupplierPrice",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: () => { return this.PurchaseOrder ? this.PurchaseOrder.LockdownType < 1 : true },
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "SUPVALUE",
                    displayName: "Supplier Value",
                    field: "SupplierValue",
                    type: "number",
                    cellFilter: "number: 5",
                    cellClass: 'text-right',
                    width: 125,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <p class="input-group-sm">
                                        <input type="number" name="supplierPrice" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.0000000000001" />
                                    </p>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return cellValue >= searchTerm;
                        }
                    }
                }, {
                    name: "CURRENCY",
                    displayName: "Currency",
                    field: "Currency",
                    width: 100,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }]
            };

            registerSkuGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPOSkuList = gridApi;

                this.apiPOSkuList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiPOSkuList.selection.selectRow(gridSelection.row.entity);
                });
            }

            gvwPODI: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                onRegisterApi: (gridApi) => { this.registerDIGridApi(gridApi); },
                showGridFooter: true,
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ui-sref="auth.PurchaseOrders.Update.Di({diId: row.entity.Id})" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "PRODCODE",
                    displayName: "Code",
                    field: "Code",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "SEQNUM",
                    displayName: "#",
                    field: "SequenceNumber",
                    enableFiltering: false,
                    type: "number",
                    width: 35
                }, {
                    name: "ROUTECODE",
                    displayName: "Route",
                    field: "RouteCode",
                    width: 150,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    }, 
                    {
                        name: "OriginPort",
                        displayName: "Origin",
                        field: "OriginPort",
                        width: 150,
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    },
                    {
                    name: "DESTICODE",
                    displayName: "Destination",
                    field: "DestinationCountryCode",
                    width: 100,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }, {
                    name: "DestinationCountryPort",
                    displayName: "Destination Port",
                    field: "DestinationCountryPort",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                        </div>`
                },

                    {
                    name: "TRANSCODE",
                    displayName: "Mode",
                    field: "TransportModeCode",
                    width: 75,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                    },
                    {
                        name: "SupplierDeliveryDue",
                        displayName: "Handover Date",
                        field: "SupplierDeliveryDue",
                        width: 125,
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                        
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue: moment.Moment) => {
                                return cellValue.isSameOrAfter(searchTerm);
                            }
                        }
                    },
                    {
                        name: "ETA",
                        displayName: "ETA",
                        field: "EstimatedDateOfArrival",
                        width: 100,
                        cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                            </div>`,
                        filter: {
                            condition: (searchTerm, cellValue: moment.Moment) => {
                                return cellValue.isSameOrAfter(searchTerm);
                            }
                        }
                    },
                    {
                    name: "NBEFORE",
                    displayName: "Not Before",
                    field: "NotBeforeDate",
                    width: 100,
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                        
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue: moment.Moment) => {
                            return cellValue.isSameOrAfter(searchTerm);
                        }
                    }
                }, {
                    name: "NAFTER",
                    displayName: "Not After",
                    field: "NotAfterDate",
                    width: 100,
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="orderDate"></gts-date>
                                        
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue: moment.Moment) => {
                            return cellValue.isSameOrAfter(searchTerm);
                        }
                    }
                }]
            };

            registerDIGridApi(gridApi: uiGrid.IGridApi) {
                this.apiDIList = gridApi;
            }

            loadSKUs(searchText: string) {
                if (this.PurchaseOrder) {
                    return this.skuService.getDropdownList(this.PurchaseOrder.OwnerEntityId, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            skuChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.purchase.IPurchaseOrderDetailSKUDisplay) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'po.update'
                    },
                        () => {
                            return this.skuService.getSKU().query({ skuId: model.Id }, (defaults: interfaces.master.ISKU) => {

                                entity.PurchaseOrderId = this.PurchaseOrder.Id;
                                entity.SKU = model;
                                entity.ProductId = defaults.ProductId;
                                entity.ProductCode = defaults.Product.Code;
                                entity.OrderUnitOfMeasureCode = defaults.Product.UnitOfMeasureCode;
                                entity.OrderUoMId = defaults.Product.OrderUnitOfMeasureId;
                                entity.PricingUnitOfMeasureCode = defaults.Product.UnitOfPricingCode;
                                entity.PricingUoMId = defaults.Product.PricingUnitOfMeasureId;
                                entity.OrderedQty = 0;
                                entity.PriceQty = 0;
                                entity.SupplierPrice = 0;
                                entity.SupplierValue = 0;

                                this.$timeout(() => {
                                    this.apiPOSkuList.rowEdit.setRowsDirty([entity]);
                                });
                            });
                        });
                }
            };

            loadCurrencies() {
                var defered = this.$q.defer<Array<interfaces.applicationcore.IDropdownModel>>();

                this.currencyService.getCurrencyDropdownList().query((currencyList: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(currencyList);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });


                return defered.promise;
            }

            loadTermsOfPayments(searchText: string) {
                var defered = this.$q.defer();

                if (this.PurchaseOrder) {
                    this.termsOfPaymentService.getTermsOfPaymentDropdown(this.PurchaseOrder.OwnerEntityId, searchText).query({},(termsOfPaymentList: Array<interfaces.applicationcore.IDropdownModel>) => {
                        defered.resolve(termsOfPaymentList);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defered.resolve([]);
                    });
                } else {
                    defered.resolve([]);
                }

                return defered.promise;
            }

            loadCountries() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadTermsOfDeliveries() {
                var defered = this.$q.defer();

                if (!this.PurchaseOrder || !this.PurchaseOrder.Incoterm) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.PurchaseOrder.OwnerEntityId, incoTermId: this.PurchaseOrder.Incoterm.Id }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadIncoTerms() {
                var defered = this.$q.defer();

                this.incotermService.getDropdownList().query((data: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadConsignees(searchText: string) {
                var defered = this.$q.defer();

                if (!this.PurchaseOrder) {
                    defered.resolve([]);
                    return defered.promise;
                }

                this.consigneeService.getDropdownList(this.PurchaseOrder.OwnerEntityId, searchText).query({}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadPO() {
                if (this.apiPOSkuList) {
                    this.apiPOSkuList.rowEdit.flushDirtyRows();
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        return this.purchaseOrderService.getPurchaseOrder().query(this.PurchaseOrderSearch, (result) => {
                            this.PurchaseOrder = result;
                            this.gvwPOSku.data = this.PurchaseOrder.PurchaseOrderDetailSKUList;
                            this.gvwPODI.data = this.PurchaseOrder.DeliveryInstructionList;
                            this.gvwPurchaseOrderAttribute.data = result.PurchaseOrderAttributeList;

                            this.poNumber = this.PurchaseOrder.OrderNumber;
                            this.getViewImportConsignmentRight();
                            this.getEditImportConsignmentRight();
                            this.loadControls();
                            this.loadStatus();

                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            getViewImportConsignmentRight() {
                this.viewConsignmentRight = false;
                return this.menuService.getGTSConnectMenuItem(379).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.viewConsignmentRight = true;
                    }
                }).$promise;
            }    

            getEditImportConsignmentRight() {
                this.editConsingmentRight = false;
                return this.menuService.getGTSConnectMenuItem(607).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.editConsingmentRight = true;
                    }
                }).$promise;
            }                


            loadStatus() {
                this.POStatus = {
                    StatusName: this.PurchaseOrder.StatusCode + ' - ' + this.PurchaseOrder.StatusName,
                    Id: this.PurchaseOrder.Id,
                    EntityId: this.PurchaseOrder.OwnerEntityId,
                    CurrentStatusId: this.PurchaseOrder.StatusId,
                    CurrentStatusComments: this.PurchaseOrder.StatusLastComments,
                    CurrentStatusStampDate: this.PurchaseOrder.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.PurchaseOrder.StatusLastUpdateStampFullName,
                    CurrentStatusType: Enum.EnumStatusType.POStatus,
                    EnumStatusType: Enum.EnumStatusType.POStatus,
                    EnumStatusAuthorisationType: Enum.EnumStatusAuthorisationType.PurchaseOrder,
                    IsEditable: true
                };

                //Lockdown Status change if User does not have Edit Rights to Designation i.e. Lockdown Type = Full
                if (this.PurchaseOrder.LockdownType >= 5){
                    this.POStatus.IsEditable = false;
                }
            }

            public incoTermChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.termsOfDeliveryList = [];
                this.PurchaseOrder.TermsOfDelivery = undefined;

            }

            printDiDocuments() {
                if (this.apiDIList && this.apiDIList.selection.getSelectedRows().length > 0) {
                    var selected = this.apiDIList.selection.getSelectedRows()[0];

                    this.$state.go("auth.PurchaseOrders.Update.Di.Documents", { diId: selected.Id });
                } else {
                    this.generalService.displayMessage("Please select a Delivery Instruction", Enum.EnumMessageType.Warning);
                }
            }

            SavePurchaseOrder() {
                var defered = this.$q.defer();

                if (this.apiPOSkuList) {
                    this.PurchaseOrder.PurchaseOrderDetailSKUList = _.map(this.apiPOSkuList.rowEdit.getDirtyRows(), 'entity');
                } else {
                    this.PurchaseOrder.PurchaseOrderDetailSKUList = [];
                }

                this.purchaseOrderService.savePurchaseOrder().query(this.PurchaseOrder,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                        if (this.apiPOSkuList) {
                            this.apiPOSkuList.rowEdit.setRowsClean(this.PurchaseOrder.PurchaseOrderDetailSKUList);
                        }

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            
            ChildCostingUpdate() {
                var defered = this.$q.defer();

                this.purchaseOrderService.updateChildOrderCosting().save({
                    purchaseOrderId: this.PurchaseOrder.Id,
                    LinkedPurchaseOrderId: this.PurchaseOrder.LinkedPurchaseOrderId,
                    CopyCostingFromLinkedOrder: this.PurchaseOrder.CopyCostingFromLinkedOrder
                },
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            CopyCostingChange() {
                this.generalService.displayConfirmationBox("Confirm", "Are you sure you want to update the Costing?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'po.update'
                        },
                            () => {
                                return this.ChildCostingUpdate().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadPO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }


            Save() {
                this.generalService.displayConfirmationBox("Confirm", "Save Purchase Order?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'po.update'
                        },
                            () => {
                                return this.SavePurchaseOrder().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadPO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            });
                    }
                });
            }

            SaveClose() {
                this.generalService.displayConfirmationBox("Confirm", "Save Purchase Order?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'po.update'
                        },
                            () => {
                                return this.SavePurchaseOrder().then((data) => {
                                    this.$state.go("^");

                                }, (failureData) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            GenerateNewVersion()
            {
                var defered = this.$q.defer();

                this.purchaseOrderService.createNewVersion().save(this.PurchaseOrder,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            NewVersion() {
                this.generalService.displayConfirmationBox("Confirm", "Create New Version of the Purchase Order?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'po.update'
                        },
                            () => {
                                return this.GenerateNewVersion().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadPO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }


            GoCalculateExchangeRate() {
                var defered = this.$q.defer();

                this.purchaseOrderService.recalculateExchangeRate().save(this.PurchaseOrder,
                    (result: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(result);
                        defered.resolve(result);

                    }, (failureMessage) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                        defered.reject(failureMessage);
                    });

                return defered.promise;
            }

            RecalculateExchangeRate() {
                this.generalService.displayConfirmationBox("Confirm", "Are you sure you want to recalculate the exchange rate?").then((result) => {

                    if (result) {
                        this.bsLoadingOverlayService.wrap({
                            referenceId: 'po.update'
                        },
                            () => {
                                return this.GoCalculateExchangeRate().then((data) => {
                                    this.$anchorScroll("topAnchor");
                                    this.loadPO();
                                }, (data) => {
                                    this.$anchorScroll("topAnchor");
                                });
                            })
                    }
                });
            }

            Close() {
                this.$state.go("^");
            }

            ViewConsignment = (conId: number) => {
                var url = '#!/ConsignmentImports/UpdateImport/'+conId;
                window.open(url, '_blank');
              }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.PurchaseOrder.OrderNumber, systemType: Enum.EnumSystemType.PurchaseOrder, ownerEntityId: this.PurchaseOrder.OwnerEntityId , showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.PurchaseOrder.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'po.update'
                },
                    () => {
                        return this.purchaseOrderService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                            //this.$state.reload();
                            this.generalService.displayMessageHandler(data);
                            this.loadPO();
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                    });


            };

            addInlineSku() {
                var poSku = {
                    PurchaseOrderId: this.PurchaseOrder.Id,
                    CurrencyId: this.PurchaseOrder.Currency.Id,
                    Currency: this.PurchaseOrder.Currency.Code
                };

                (<Array<any>>(this.gvwPOSku.data)).splice(0, 0, poSku);
            }

            detailRemoveClick() {
                var selectedRows = <Array<interfaces.purchase.IPurchaseOrderDetailSKUDisplay>>this.apiPOSkuList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].PurchaseOrderDetailId || selectedRows[0].PurchaseOrderDetailId === 0) {
                    this.apiPOSkuList.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwPOSku.data)).splice((<Array<any>>(this.gvwPOSku.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the Purchase Order Line?").then((result: boolean) => {
                    if (result) {
                        this.removeDetailLine(selectedRows[0].PurchaseOrderDetailId);
                    }
                });
            }

            removeDetailLine(purchaseOrderDetailId: number) {
                this.purchaseOrderSKUService.delete().delete({ purchaseOrderDetailId: purchaseOrderDetailId }, (result) => {
                    this.generalService.displayMessageHandler(result);

                    this.loadPO();

                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }

            updateComments() {
                this.generalService.displayConfirmationBox("Confirm?", "Any existing comments will be overwritten and the Purchase Order will be saved, do you want to continue?").then((result) => {
                    if (result) {
                        this.purchaseOrderService.generatePODIComments().save(this.PurchaseOrder,
                            (result: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(result);
                                //this.$state.reload();
                                this.loadPO();
                                this.generalService.displayMessage("Updated Comments and Instructions Successfully.", Enum.EnumMessageType.Success);
                            }, (failureMessage) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                            });
                        this.$anchorScroll("topAnchor");
                    }
                });
            }

            printDocuments() {
                this.$state.go("auth.PurchaseOrders.Update.Documents");
            }

            registerPurchaseOrderAttributeApi(gridApi: uiGrid.IGridApi) {
                this.apiCA = gridApi;
            }

            removeInlineCA() {
                var selectedRow = <interfaces.master.ICustomAttributeValue>this.apiCA.selection.getSelectedRows()[0];
                
                if (selectedRow && selectedRow.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update'
                    }, () => {
                        return this.customAttributeService.deleteCustomAttributeValue(selectedRow.Id).save({ Id: selectedRow.Id }, (result) => {
                            this.generalService.displayMessageHandler(result);
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'con.update'
                                }, () => {
                                        this.loadPO();
                                });
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        });
                    });
                } else if (selectedRow) {
                    _.remove(this.gvwPurchaseOrderAttribute.data, (o) => {
                        return o === selectedRow;
                    });
                }
            }

            checkBoxAttributeChanged(entity) {
                if (this.apiCA) {
                    this.apiCA.rowEdit.setRowsDirty([entity]);
                }
            }

            attributeValueChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICustomAttributeValue) {
                if (model) {
                    entity.AttributeValue = model.Description;

                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            attributeValueOtherChanged(entity: interfaces.master.ICustomAttributeValue) {
                if (entity) {
                    if (this.apiCA) {
                        this.apiCA.rowEdit.setRowsDirty([entity]);
                    }
                }
            }

            loadAttributeAnswerOptionList(id) {
                return this.customAttributeAnswerOptionService.GetDropDownListByAttribute().query({
                    attributeId: id
                }, (resultList) => {
                    this.attributeAnswerOptionList = resultList;
                }).$promise;
            }

            insertCA() {
                this.PurchaseOrder.PurchaseOrderAttributeList.push(<interfaces.master.ICustomAttributeValue>{
                    Id: 0,
                    Attribute: <interfaces.master.ICustomAttribute>{ Id: 0, AttributeType: 0, IsNotEditable: false },
                    CategoryType: 796,
                    CategoryKey: this.PurchaseOrder.Id,
                    IsActive: true
                });
            }

            loadAttributeList() {
                return this.customAttributeService.GetCustomAttributeByCategory(796,this.PurchaseOrder.OwnerEntityId).query(
                {
                }, (resultList:interfaces.master.ICustomAttribute[]) => {
                    this.attributeList = resultList;
                }).$promise;
            }

            gvwPurchaseOrderAttribute: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ng-disabled="row.entity.Attribute.IsNotEditable" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => {
                    this.apiCA = gridApi;
                    this.apiCA.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiCA.selection.selectRow(newCol.row.entity);
                        });
                    });
                  
                },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.pOUpdateCtrl.removeInlineCA()" ng-disabled="row.entity.Attribute.IsNotEditable" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 60,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton">
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.pOUpdateCtrl.insertCA()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    },
                    {
                        name: 'Attribute',
                        displayName: '* Attribute',
                        field: 'Attribute',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="row.entity.Attribute.IsNotEditable">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-disabled="row.entity.Attribute.IsNotEditable" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.pOUpdateCtrl.loadAttributeList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 210
                    },
                    {
                        name: 'AttributeValue',
                        displayName: '* Attribute Value',
                        field: 'CustomAttributeAnswerOption',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeNumericValue}}</div>
                                       <div class="ui-grid-cell-contents" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable">{{row.entity.AttributeDateValue | momentDateFilter: 'YYYY/MM/DD  HH:mm'}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                        <gts-dropdown ng-if="row.entity.Attribute.AttributeType == 57" ng-disabled="row.entity.Attribute.IsNotEditable" ng-change="grid.appScope.pOUpdateCtrl.attributeValueChanged(model, row.entity)"  ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}-AttributeValueDD" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.pOUpdateCtrl.loadAttributeAnswerOptionList(row.entity.Attribute.Id)"></gts-dropdown>                                                                                                                                               
                                        
                                        <p class="input-group-sm" ng-if="row.entity.Attribute.AttributeType == 58">
                                        <input type="text" ng-change="grid.appScope.pOUpdateCtrl.attributeValueOtherChanged( row.entity)" ng-if="row.entity.Attribute.AttributeType == 58" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValueText" ng-model="row.entity.AttributeValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                                        </p>

                                        <p class="input-group-sm"  ng-if="row.entity.Attribute.AttributeType == 556">
                                        <input type="number" ng-change="grid.appScope.pOUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 556" ng-disabled="row.entity.Attribute.IsNotEditable"  ignore-wheel name="AttributeNumericValue" ng-model="row.entity.AttributeNumericValue" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                        <gts-date ng-change="grid.appScope.pOUpdateCtrl.attributeValueOtherChanged(row.entity)" ng-if="row.entity.Attribute.AttributeType == 557" ng-disabled="row.entity.Attribute.IsNotEditable"   format="yyyy/MM/dd HH:mm"  show-time="true"  ng-model="row.entity.AttributeDateValue" name="{{MODEL_COL_FIELD.$$hashKey}}-endDate"></gts-date>
                                </form>
                               `,
                        enableSorting: false,
                        width: 210
                    }
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive" ng-change="grid.appScope.pOUpdateCtrl.checkBoxAttributeChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="row.entity.Attribute.IsNotEditable" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };
        }

        angular.module("app").controller("purchaseOrderUpdateCtrl", controllers.purchase.PurchaseOrderUpdateCtrl);
    }
}